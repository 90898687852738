@import './shared/styles/_vuetify-overrides.scss';

















































































































































































































































































































































































































































































































































































































































































































































































.cassie-html-field-container > div {
	padding-top: 16px !important;
}

.cassie-html-error-container {
	color: white;
}

.cassie-disabled-textarea {
	padding: 10px !important;
	color: var(--global-cassie-disabled-textarea-color);
	border: var(--global-cassie-disabled-textarea-border);
}
