@import './shared/styles/_vuetify-overrides.scss';










































































::v-deep .cookie-policy-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
	border-radius: 10px 10px 0 0;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(167, 205, 224, 1);
  -moz-box-shadow: 0px 0px 40px 0px rgba(167, 205, 224, 1);
  box-shadow: 0px 0px 40px 0px rgba(167, 205, 224, 1);
  margin-bottom: 15px;
}

::v-deep .cookie-policy-table > thead {
  background: none;
  color: white;
  font-weight: bold;
  text-align: left;
}

::v-deep .cookie-policy-table > thead > tr > th:nth-child(2) {
    width: 40%;
}

::v-deep .cookie-policy-table > tbody > tr > td:nth-child(2) {
    width: 40%;
	word-break: break-word;
}

::v-deep .cookie-policy-table > thead > tr > th {
  background: #0678aa;
  width: 25%;
	padding: 8px 15px;
}

::v-deep .cookie-policy-table > thead > tr > th:first-child {
  border-radius: 10px 0 0 0;
}

::v-deep .cookie-policy-table > thead > tr > th:last-child {
  border-radius: 0 10px 0 0;
}

::v-deep .cookie-policy-table > tbody > tr > td {
  padding: 8px 15px;
}

::v-deep .cookie-policy-table > tbody > tr:nth-child(even) {
  background: #e8f4f7;
}

::v-deep .cookie-policy-table > thead > tr > th.group-header {
  border-radius: 10px 10px 0 0;
  background-color: #666;
}

::v-deep .cookie-policy-table > thead > tr.no-radius > th {
  border-radius: 0 0 0 0;
}
