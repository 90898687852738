@import './shared/styles/_vuetify-overrides.scss';




















































































































.cassie-disabled-button {
  color: var(--global-cassie-disabled-button-color);
  background-color: var(--global-cassie-disabled-button-background-color);
  border: var(--global-cassie-disabled-button-border);
  -webkit-box-shadow: revert;
  box-shadow: revert;
}

.cassie-disabled-textarea {
  padding: 10px !important;
  color: var(--global-cassie-disabled-textarea-color);
  border: var(--global-cassie-disabled-textarea-border);
}

.cassie-html-field-container > div {
  padding-top: 16px !important;
}

.cassie-overlay-card {
  max-height: 70vh !important;
  overflow-y: auto !important;
}

@media (max-width: var(--global-cassie-breakpoint-md)) {
  .cassie-overlay-card {
    height: 75vh;
  }
}
@media (max-width: var(--global-cassie-breakpoint-lg)) {
  .cassie-overlay-card {
    height: 85vh;
  }
}
